<template>
    <job-posting-layout>
      <template #topContent>
        <div class="stepContent" >
          <step-count :count="3" id="stepCount"/>
          <step-title title="What type of job are you offering?" id="stepTitle"/>
          <label class="err" v-if="isSelected">Please select an offer level</label>
          <div class=" tw-flex tw-w-full  tw-flex-col-reverse lg:tw-flex-row tw-justify-between">
          <div class="lg:tw-mt-12 lg:tw-w-7/12">
            <item-card :title="offer" v-for="offer in jobOffers" :id="offer"
                       :key="offer" @selectedJobOffer="selectedJobOffer(offer)" :selected="selectedOffer" />
          </div>

        </div>
        </div>
      </template>
      <template #bottomNav>
        <div class="tw-relative  tw-flex-col" style="width:100vw;">
          <bottom-navbar @click:back="handleBack" @click:next="handleUpdatePosting" :previous="true" :loading="buttonLoading" />
        </div>

      </template>
    </job-posting-layout>
</template>

<script>
import {createJob, updateJob} from "@/services/api/APIService";
import BottomNavbar from "../reuseables/BottomNavbar";
import JobPostingLayout from "../layouts/JobPostingLayout";
import StepCount from "../reuseables/StepCount";
import StepTitle from "../reuseables/StepTitle";
import ItemCard from "../reuseables/ItemCard";
import {getJobById} from "../../../services/api/APIService";
export default {
  name: "StepThree",
  components: {ItemCard, StepTitle, StepCount, JobPostingLayout, BottomNavbar},
  data(){
    return{
      jobOffers : ["Full-Time","Part-Time","Contract","Internship","Flexible"],
      selectedOffer : "",
      buttonLoading : false,
      isSelected : false,
    }
  },
  created() {
    const getReturnedSelectedDetails = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"))
    if (getReturnedSelectedDetails.id) {
      let data = {}
      data.id = getReturnedSelectedDetails.id
      data.site_name = this.$siteName()
      getJobById(data).then(res =>{
        sessionStorage.setItem('returnedSelectedDetails', JSON.stringify(res.data))
        this.selectedOffer = getReturnedSelectedDetails.type;
      }).catch(err =>{
            this.$displaySnackbar({message:err.response.data.detail, success:false})
          })
    }
  },
  methods: {
    handleBack(){
        this.$router.push({
          name: "Job-stepTwo",
        })
    },
    selectedJobOffer(selectedOffer) {
      this.selectedOffer = selectedOffer
    },
    async handleUpdatePosting() {
      this.buttonLoading = true
      const getReturnedSelectedDetails = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"))
      if (!this.selectedOffer){
        this.isSelected = true
        this.buttonLoading = false
      }
      else {
        this.isSelected = false
        getReturnedSelectedDetails.type= this.selectedOffer
        getReturnedSelectedDetails.site_name = this.$siteName()
        console.log(getReturnedSelectedDetails, "before send")
        if(getReturnedSelectedDetails.id){
          try {
            const response = await updateJob(getReturnedSelectedDetails.id, getReturnedSelectedDetails)
            sessionStorage.setItem("returnedSelectedDetails", JSON.stringify(response.data))
            this.buttonLoading =false
            await this.$router.push({
              path: "4",
              name: "Job-stepFour",
            })}
          catch (error) {
            this.buttonLoading = false
            this.$displaySnackbar({
              message: 'An error occurred, kindly check your details or network',
              success: false
            })
          }
        }
        else {
          try {
            const response = await createJob(getReturnedSelectedDetails)
            sessionStorage.setItem("returnedSelectedDetails", JSON.stringify(response.data))
            this.buttonLoading = false
            await this.$router.push({
              path: "4",
              name: "Job-stepFour",
            })
          } catch (error) {
            this.$displaySnackbar({
              message: 'An error occurred, kindly check your details or network',
              success: false
            })
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.err{
  color: red;
  font-size: 14px;
  margin-top: -1rem;
}
</style>