<template>
<div class="tw-items-center tw-cursor-pointer tw-flex-col tw-py-5 tw-px-3 tw-mt-5"
     @click="handleSelected" :class="[selected === title ? 'selected-card':'card']">
    <div class="tw-flex tw-w-full  tw-justify-between tw-items-center">
      <label class="tw-flex tw-items-center" :class="[selected === title ? ' selected-card-title-color':' card-title-color']">{{title}}</label>
      <check-circle v-show="selected === title" />
    </div>
</div>
</template>

<script>
import CheckCircle from "../icons/CheckCircle";
export default {
  name: "ItemCard",
  components: {CheckCircle},
  props:{
    title:[String],
    selected: {
      type: String,
    }
  },
  data(){
    return{

    }
  },
  methods:{
    handleSelected(){
      this.$emit('selectedJobOffer',this.title)
    }
  }
}
</script>

<style scoped lang="scss">
.card-title{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px;
}
.selected-card-title-color{
  color: #008EEF;
}
.card-title-color{
  color: #1E323F;
}
.card{
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1E323F
}
.card:hover{
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
}
.selected-card{
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}

.desc{
  font-family: DM Sans,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
</style>