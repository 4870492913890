<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.0626" cy="12.2384" r="12.0626" fill="#008EEF"/>
    <path d="M16.0625 9.23828L10.5625 14.7383L8.0625 12.2383" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "CheckCircle"
}
</script>

<style scoped>

</style>